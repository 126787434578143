import Cookies from "js-cookie";
import { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const resultsPerPage = 10;
  const invoiceRef = useRef("");
  const searchRef = useRef("");
  // const value = searchRef.current.value;
  // console.log(value, "mmmmmmmmmmmmmmmmmmmmmm");
  const [Search,setSeach] =useState()
  const [limitData, setLimitData] = useState(10);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [lang, setLang] = useState("en");
  const [time, setTime] = useState("");
  const [sortedField, setSortedField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [invoice, setInvoice] = useState(null);
  const [zone, setZone] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [navBar, setNavBar] = useState(true);
  const { i18n } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [Latitude,setLatitude]=useState(null)
  const [Longitude,setLongitude]=useState(null)
  const [location, setLocation] = useState("");
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [dateRangeOption, setDateRangeOption] = useState("Anytime");
  const [pastData,setpastData]=useState()
  const [addOnType,setaddOnType] =useState("")
  const [Type,setType] =useState("")
  const [category1, setCategory1]=useState(null)
  const [categoryId, setCategoryd]=useState(null)
  const [subcategory,setsubcategory]=useState(null)
  const [locationValue, setLocationValue] = useState("");
  const [subCategoryy,setsubCategoryy] =useState([]);
  console.log(startDate,"endDate")
  console.log(endDate,"endDate")

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleLanguageChange = (lang) => {
    Cookies.set("i18next", lang);
    i18n.changeLanguage(lang);
    setLang(lang);
  };

  const handleChangePage = (p) => {
    console.log(p,"sxmksmxsmx")
    setCurrentPage(p);
  };
  const { ready, value, suggestions, setValue, clearSuggestions } =
  usePlacesAutocomplete({
    requestOptions: {
      types: [], // Specify the type of results (e.g., cities)
      // Add more options as needed
    },
    debounce: 300,
  });

const ref = useOnclickOutside(() => {
  clearSuggestions();
});

console.log(value, "hello");
const handleChange = (e) => {
  const inputValue = e.target.value;
console.log("inputValue--Location",inputValue);
  setValue(inputValue);

  // Reset the state when the input is changed
  setIsLocationSelected(false);
};

const handleClearInput = () => {
  // Reset the state when the input is cleared
  setIsLocationSelected(false);
};
const handleSelect = async ({ description }) => {
  setValue(description, true);
  clearSuggestions();

  try {
  console.log(description,"reciree");

    const results = await getGeocode({ address: description });
    const { lat, lng } = await getLatLng(results[0]);
  console.log(results,"reciree");
    // Use lat and lng as needed
    fetchLocation(lat, lng);

    setLocation(description);
    
    setIsLocationSelected(true); // Update the state
  } catch (error) {
    console.error("Error fetching location:", error);
  }
};

const fetchLocation = async (lat, long) => {
  // Your fetch location logic
  console.log(`Fetched location: Latitude ${lat}, Longitude ${long}`);
  console.log(lat)
  console.log(long)
  // Latitude,
  setLatitude(lat)
  // Longitude,
  setLongitude(long)
 
  // ProductServices.getAllRoomRent({
  //   page: currentPage,
  //   limit: limitData,
  //   category: category,
  //   title: searchText,
  //   status: sortedField,
  //   longitude:long,
  //   latitude:lat,
  // })
  // setLat(lat)
  // setLan(long)
};

useEffect(() => {
  // Additional logic when location state changes
  console.log("Location state changed:", location);
}, [location]);



  

  

  const handleSubmitForAll = (e) => {
    e.preventDefault();
    if (!searchRef?.current?.value) return setSearchText(null);
    console.log(searchRef?.current?.value,"hjjjjjjjjjjjjjjjjjjk")
    // debugger
    setSearchText(searchRef?.current?.value);
    setCategory(null);
  };

  const handleChangeCategory =(e)=>{
     console.log(e.target,"hhhh")
    const selectedValue = e.target.value;
    // debugger
    setCategory1(selectedValue)
    const selectedOption =  e.target.options[e.target.selectedIndex].id;
    setCategoryd(selectedOption)
    console.log(selectedOption,"selectedOptionselectedOptionselectedOption")
    if(selectedValue=="All"){
      setsubCategoryy([])
    }

    // const selectedKey = selectedOption.dataset.key;
  }

  useEffect(() => {
    const language = Cookies.get("i18next");
    // console.log("lang", language);
    const enLang = [
      "en-US",
      "en-GB",
      "en-TT",
      "en-ZA",
      "en-NZ",
      "en-JM",
      "en-IE",
      "en-CA",
      "en-BZ",
      "en-au",
    ];
    if (enLang.includes(language)) {
      setLang("en");
      // console.log("lang", lang, "included");
    } else {
      setLang(Cookies.get("i18next"));
    }
  }, [lang, windowDimension]);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        closeBulkDrawer,
        isBulkDrawerOpen,
        toggleBulkDrawer,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        lang,
        setLang,
        handleLanguageChange,
        currentPage,
        setCurrentPage,
        handleChangePage,
        searchText,
        setSearchText,
        category,
        setCategory,
        searchRef,
        handleSubmitForAll,
        status,
        setStatus,
        zone,
        setZone,
        time,
        setTime,
        sortedField,
        setSortedField,
        resultsPerPage,
        limitData,
        setLimitData,
        windowDimension,
        modalOpen,
        setModalOpen,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        loading,
        setLoading,
        invoice,
        setInvoice,
        invoiceRef,
        setNavBar,
        navBar,
        tabIndex,
        setTabIndex,
        setSeach,
        Search,
        Latitude,
        setLatitude,
        Longitude,
        setLongitude,
        location,
        setLocation,
        isLocationSelected,
        setIsLocationSelected,
        dateRangeOption,
        setDateRangeOption,
        handleChange,
        value,
        handleClearInput,
        suggestions,
        handleSelect,
        setValue,
        pastData,
        setpastData,
        addOnType,
        setaddOnType,
        Type,
        setType,
        category1, 
        setCategory1,
        handleChangeCategory,
        categoryId,
        setCategoryd,
        subcategory,
        setsubcategory,
        subCategoryy,
        setsubCategoryy


      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

// category1, setCategory1]=useState(null)
// const [categoryId, setCategoryd]
// const [addOnType,setaddOnType] =useState("")
// const [Type,setType] =useState("")
// pastData,setpastData
// const [location, setLocation] = useState("");
// const [isLocationSelected, setIsLocationSelected] = useState(false);
// const [dateRangeOption, setDateRangeOption] = useState("Anytime");