import { lazy } from "react";
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Attributes = lazy(() => import("../pages/Attributes"));
const ChildAttributes = lazy(() => import("../pages/ChildAttributes"));
const Products = lazy(() => import("../pages/Products"));
const Rooms_Rent = lazy(() => import("../pages/RoomsRent"));
const Categories = lazy(() => import("../pages/Categories"));
const SubCategories = lazy(() => import("../pages/SubCategories"));

const Event = lazy(() => import("../pages/Event"));
const Jobs = lazy(() => import("../pages/Jobs"));
const BuySell = lazy(() => import("../pages/BuySell"));
const LocalBiz = lazy(() => import("../pages/LocalBiz"));
const CareServices = lazy(() => import("../pages/CareServices"));

const ProductDetails = lazy(() => import("../pages/RentalDetails"));
const Category = lazy(() => import("../pages/Category"));
const ChildCategory = lazy(() => import("../pages/ChildCategory"));
const Staff = lazy(() => import("../pages/Staff"));
const Customers = lazy(() => import("../pages/Customers"));
const CustomersManagement = lazy(() => import("../pages/CustomersManagement"));
const Notifications = lazy(() => import("../pages/Notifications"));
const CreateNotification = lazy(() => import("../pages/CreateNotification"));

const CustomerOrder = lazy(() => import("../pages/CustomerOrder"));
const OrderInvoice = lazy(() => import("../pages/OrderInvoice"));
const Coupons = lazy(() => import("../pages/Coupons"));
const Page404 = lazy(() => import("../pages/404"));
const ComingSoon = lazy(() => import("../pages/ComingSoon"));
const EditProfile = lazy(() => import("../pages/EditProfile"));
const Languages = lazy(() => import("../pages/Languages"));
const Currencies = lazy(() => import("../pages/Currencies"));
const Setting = lazy(() => import("../pages/Setting"));
const RentDetails =lazy(()=>import("../pages/RentalDetails"))
const NotificationDetails =lazy(()=>import("../pages/NotificationDetails"))
const EventDetails =lazy(()=>import("../pages/EventDeatils"))
const Jobsdetails =lazy(()=>import("../pages/jobDetails"))
const BuyandSellDetails =lazy(()=>import("../pages/BuyandSellDetails"))
const CareServicedetail =lazy(()=>import('../pages/CareServicedetail'))
const LocalbizDetails =lazy(()=>import('../pages/LocalbizDetails'))
const About =lazy(()=>import('../pages/About'))
 const Privacy =lazy(()=>import('../pages/PrivacyPolicy'))
const TermsCondtions =lazy(()=>import('../pages/TermsCondtions'))
const UpdatePassword  =lazy(()=>import('../pages/Update_password'))
const Payment =lazy(()=>import('../pages/Payment'))
// import Banner from "pages/Banner";
const Banner =lazy(()=>import('../pages/Banner'))
// import AddBanner from "components/modal/AddBanner";
const AddBanner =lazy(()=>import('../components/modal/AddBanner'))
const EditBanner =lazy(()=>import('../components/modal/EditBanner'))
const ViewBanner =lazy(()=>import('../pages/viewBanner'))
const HelpAndSupport =lazy(()=>import('../pages/HelpAndSupport'))
// const HelpAndSupport =lazy(()=>('../page/HelpAndSupport'))
// import HelpAndSupport from "pages/Help&Support";


const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/rooms_rent",
    component: Rooms_Rent,
  },
  {
    path: "/rooms_rent_categories",
    component: Categories,
  },  

  {
    path: "/job_categories",
    component: Categories,
  },

  {
    path: "/event_categories",
    component: Categories,
  },

  {
    path: "/buysell_categories",
    component: Categories,
  },


  {
    path: "/local_biz_categories",
    component: Categories,
  },

  {
    path: "/care_services_categories",
    component: Categories,
  },


  {
    path: "/sub_categories/:id",
    component: SubCategories,
  },


  {
    path: "/rooms_rent/:rentalId",
    component: RentDetails,
  },  
  {
    path: "/notification-details/:notificationId",
    component: NotificationDetails,
  }, 
  {
    path: "/events",
    component: Event,
  },
  {
    path: "/events/:eventId",
    component: EventDetails,
  },  
  {
    path: "/buysell",
    component: BuySell,
  },
  {
     path:"/buysell/:id",
     component:BuyandSellDetails
  },
  {
    path: "/local_biz",
    component: LocalBiz,
  },  
  {
    path: "/local_biz/:localId",
    component: LocalbizDetails,
  }, 
  {
    path: "/care_services",
    component: CareServices,
  },
  {
    path: "/care_services/:careID",
    component: CareServicedetail,
  },  
  {
    path: "/jobs",
    component: Jobs,
  },
  {
    path: "/jobs/:jobId",
    component: Jobsdetails,
  },

  {
    path: "/customers",
    component: Customers,
  },
  {
    path: "/notifications",
    component: Notifications,
  },
  {
    path: "/role-management",
    component: CustomersManagement,
  },
  {
    path: "/create-notification",
    component: CreateNotification,
  },
  {
    path: "/re-create-notification",
    component: CreateNotification,
  },
  {
    path: "/update-notification/:id",
    component: CreateNotification,
  },
  {
    path: "/notification-details",
    component: Notifications,
  },
  {
    path: "/customer-order/:id",
    component: CustomerOrder,
  },
  {
    path: "/our-staff",
    component: Staff,
  },
 
  { path: "/settings", component: Setting },
  {
    path: "/404",
    component: Page404,
  },
  {
    path: "/coming-soon",
    component: ComingSoon,
  },
  {
    path: "/edit-profile",
    component: EditProfile,
  },
  {
    path: "/about_us",
    component: About,
  },
  {
    path: "/terms_condtions",
    component: TermsCondtions,
  },
  {
    path: "/privacy_Policy",
    component: Privacy,
  },
  {
    path: "/update_password",
    component: UpdatePassword,
  },
  {
    path:"/view_all_payment",
    component:Payment
  },

  {
    path:"/banners",
    component:Banner
  },
  {
    path:"/banners/:id",
    component:ViewBanner
  },
  {
    path:"/add-banner",
    component:AddBanner
  },
  {
    path:"/edit_banner/:id",
    component:EditBanner
  },  
  {
    path:"/support",
    component:HelpAndSupport
  }  
];

export default routes;                  
